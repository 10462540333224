import React, { Fragment } from "react";
import { graphql } from "gatsby";
import { Layout } from "~/templates/Layout";
import { PageHeading } from "~/atoms/PageHeading";
import { ReportCard } from "~/molecules/ReportCard";
import { Container } from "~/atoms/Container";
import { Pager } from "~/molecules/Pager";
import { PATH } from "~/constant/path";
import { colors } from "~/utils/colors";

interface Props {
  data: {
    allContentfulReport: GatsbyTypes.ContentfulReportConnection;
  };
  pageContext: {
    limit: number;
    skip: number;
    totalPages: number;
    currentPage: number;
  };
}

const DESCRIPTION = [
  `セミナーや展示会・イベントの開催履歴と`,
  `どんなお話をさせていただいたかを`,
  `わかりやすくレポートとしてまとめています`,
];

const IndexReport: React.FC<Props> = ({ data, pageContext }) => {
  const { currentPage, totalPages } = pageContext;
  const { allContentfulReport } = data;

  return (
    <>
      <style jsx>{`
        .pageWrapper {
          background: ${colors.bgGrayGradient};
          padding-bottom: 50px;
        }

        .reportList {
          display: flex;
          flex-wrap: wrap;
          position: relative;
          width: 100%;
        }
      `}</style>
      <Layout
        title={PATH.EVENTS_REPORT.name}
        ogpDescription={DESCRIPTION.join("")}
        breadCrumbItems={[PATH.TOP, PATH.EVENTS, PATH.EVENTS_REPORT]}
      >
        <div className="pageWrapper">
          <PageHeading title={PATH.EVENTS_REPORT.name}>
            {DESCRIPTION.map((str, index) => {
              return (
                <Fragment key={`${str}_${index}`}>
                  {DESCRIPTION.length - 1 === index ? (
                    str
                  ) : (
                    <>
                      {str}
                      <br className="pcVisible" />
                    </>
                  )}
                </Fragment>
              );
            })}
          </PageHeading>
          <Container>
            <ul className="reportList">
              {allContentfulReport.edges.map((edge) => (
                <ReportCard
                  key={edge.node.contentful_id}
                  article={edge.node}
                  href={`${PATH.EVENTS_REPORT.to}/${edge.node.contentful_id}`}
                />
              ))}
            </ul>
          </Container>
          <Pager currentPage={currentPage} totalPages={totalPages} basePath={PATH.EVENTS_REPORT.to} />
        </div>
      </Layout>
    </>
  );
};

export default IndexReport;

export const indexReportQuery = graphql`
  query IndexReport($limit: Int!, $skip: Int!) {
    allContentfulReport(
      filter: { eventCategory: { slug: { eq: "report" } } }
      sort: { fields: date, order: DESC }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          contentful_id
          title
          ogpImage {
            fluid(maxWidth: 660) {
              src
              aspectRatio
            }
          }
          date
          eventCategory {
            slug
          }
        }
      }
      totalCount
    }
  }
`;
