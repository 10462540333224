import React from "react";
import { Link } from "gatsby";
import { ReactComponent as Arrow } from "~/images/inline_svg/right_arrow.svg";
import { colors } from "~/utils/colors";
import { breakPoints } from "~/utils/variables";

const PagerItem: React.FC<{
  isActive: boolean;
  index: number;
  basePath: string;
}> = ({ isActive, index, basePath }) => {
  const pagerIndexPath = index === 1 ? basePath : `${basePath}/${index}`;

  return (
    <>
      <style jsx>{`
        .pagerItem {
          margin-right: 5px;
        }

        .pagerItem :global(a) {
          align-items: center;
          background-color: ${(isActive && colors.defaultText) || colors.white};
          border-radius: 50%;
          color: ${(isActive && colors.white) || colors.littleBrightGray};
          display: flex;
          font-size: 15px;
          height: 28px;
          line-height: 1;
          justify-content: center;
          pointer-events: ${isActive ? "none" : "auto"};
          transition: all 0.3s ease;
          width: 28px;
        }

        .pagerItem :global(a):hover {
          background-color: ${!isActive && colors.littleBrightGray};
          color: ${colors.white};
          cursor: ${isActive ? "default" : "pointer"};
        }
      `}</style>
      <li className="pagerItem">
        <Link to={pagerIndexPath}>{index}</Link>
      </li>
    </>
  );
};

export const Pager: React.FC<{
  currentPage: number;
  totalPages: number;
  basePath: string;
}> = ({ currentPage, totalPages, basePath }) => {
  const items = Array.from({ length: totalPages }).map((_, index) => {
    return (
      <PagerItem
        key={`PagerItem_${index}`}
        index={index + 1}
        isActive={currentPage - 1 === index}
        basePath={basePath}
      />
    );
  });

  return (
    <>
      <style jsx>{`
        .pager {
          margin: 30px 0 0;
          padding: 0 30px;
        }

        .pagerList {
          display: flex;
          justify-content: center;
        }

        .pagerList :global(svg) {
          fill: ${colors.littleBrightGray};
          width: 9px;
        }

        .pagerPreviousItem {
          position: relative;
        }

        .pagerPreviousItem :global(svg) {
          transform: rotate(180deg);
        }

        .pagerNextItem {
          position: relative;
        }

        .pagerPreviousItem :global(a),
        .pagerNextItem :global(a) {
          align-self: center;
          display: flex;
          justify-content: center;
          height: 30px;
          width: 30px;
        }

        @media (${breakPoints.sp}) {
          .pager {
            padding: 0 20px;
          }

          .arrow {
            position: relative;
            top: 2px;
          }
        }
      `}</style>
      <div className="pager">
        <ul className="pagerList">
          {totalPages > 1 && currentPage !== 1 && (
            <li className="pagerPreviousItem">
              <Link to={currentPage - 1 === 1 ? basePath : `${basePath}/${currentPage - 1}`}>
                <Arrow />
              </Link>
            </li>
          )}
          {items}
          {totalPages > 1 && currentPage !== totalPages && (
            <li className="pagerNextItem">
              <Link to={`${basePath}/${currentPage + 1}`}>
                <Arrow />
              </Link>
            </li>
          )}
        </ul>
      </div>
    </>
  );
};
