import React from "react";
import { Container } from "~/atoms/Container";
import { breakPoints } from "~/utils/variables";

interface Props {
  title: string;
  children?: React.ReactNode;
}

export const PageHeading: React.FC<Props> = ({ title, children }) => {
  return (
    <>
      <style jsx>{`
        .wrapper {
          margin: 30px 0;
        }

        .wrapper :global(.container) {
          align-items: center;
          flex-direction: column;
          justify-content: center;
        }

        .title {
          text-align: center;
        }

        @media (${breakPoints.pc}) {
          .wrapper {
            padding: 0 30px;
          }

          .title {
            font-size: 32px;
          }

          .description {
            font-size: 17px;
            text-align: center;
          }
        }

        @media (${breakPoints.sp}) {
          .wrapper {
            padding: 0 20px;
          }

          .title {
            font-size: 24px;
          }

          .description {
            font-size: 15px;
          }
        }
      `}</style>
      <div className="wrapper">
        <Container>
          <h1 className="title">{title}</h1>
          {children && <p className="description">{children}</p>}
        </Container>
      </div>
    </>
  );
};
